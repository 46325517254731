.translate {
  transform: translate(-50%, -50%);
}

.button:hover {
  transform: scale(1.05);
}

.button:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}


/* Code copied from W3: */

/* Customize the label (the container) */
.box {
  position: relative;
  padding: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.box input {
  position: absolute;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 30px;
  background-color: #fff;
  border-radius: 35%;
  border: 2px solid #E22726;
}

.checkmark-even {
  background-color: #F9D4D4;
}

/* On mouse-over, add a grey background color */
/* .box:hover input~.checkmark.live {
  background-color: #ccc;
} */

/* When the radio button is checked, add a blue background */
.box input:checked~.checkmark {
  background-color: #2196F3;
}

/* This was added at the end with the kind help of ChatGPT after I had issues selecting checkboxes in mobile devices */
.box input:checked:focus~.checkmark {
  background-color: #2196F3;
}


.box1, .box2, .box3, .box4, .box5, .box6, .box7 {
  grid-row-start: 1;
  grid-row-end: 1;
}

.box8, .box9, .box10, .box11, .box12, .box13, .box14, .box15, .box16, .box17 {
  grid-row-start: 2;
  grid-row-end: 2;
}

.box18, .box19, .box20, .box21, .box22, .box23, .box24, .box25, .box26, .box27 {
  grid-row-start: 3;
  grid-row-end: 3;
}

.box28, .box29, .box30, .box31, .box32, .box33, .box34, .box35, .box36, .box37 {
  grid-row-start: 4;
  grid-row-end: 4;
}

.red-box {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
}


@media screen and (max-width: 740px) {
  .form {
    border-right: none;
  }

  .form-additional {

    border-bottom: 2px solid #E22726;
    grid-template-columns: repeat(10, 1fr);

  }

  .children:last-child {
    /* border-bottom: none; */
    /* FIX IT AT THE END TO A BLACK BORDER INSTEAD BY SELECTING THE RIGHT ELEMENT */
  }



  .red-box-additional {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .box-additional-1 {
    grid-column-start: 4;
    grid-column-end: 5;
  }

  .box-additional-2 {
    grid-column-start: 5;
    grid-column-end: 6;
  }

  .box-additional-3 {
    grid-column-start: 6;
    grid-column-end: 7;
  }

  .box-additional-4 {
    grid-column-start: 7;
    grid-column-end: 8;
  }

  .box-additional-5 {
    grid-column-start: 8;
    grid-column-end: 9;
  }

  .box-additional-6 {
    grid-column-start: 9;
    grid-column-end: 10;
  }

  .box-additional-7 {
    grid-column-start: 10;
    grid-column-end: 11;
  }

}


@media screen and (max-width: 610px) {
  .form, .form-additional {
    grid-template-columns: repeat(5, 1fr);
    width: 20rem;
  }

  .form-additional {
    grid-template-rows: repeat(2, 1fr);
  }

  .box1 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 4;
    grid-column-end: 5;
  }

  .box2 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 5;
    grid-column-end: 6;
  }

  .box3, .box4, .box5, .box6, .box7 {
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .box8, .box9, .box10, .box11, .box12 {
    grid-row-start: 3;
    grid-row-end: 3;
  }

  .box13, .box14, .box15, .box16, .box17 {
    grid-row-start: 4;
    grid-row-end: 4;
  }

  .box18, .box19, .box20, .box21, .box22 {
    grid-row-start: 5;
    grid-row-end: 5;
  }

  .box23, .box24, .box25, .box26, .box27 {
    grid-row-start: 6;
    grid-row-end: 6;
  }

  .box28, .box29, .box30, .box31, .box32 {
    grid-row-start: 7;
    grid-row-end: 7;
  }

  .box33, .box34, .box35, .box36, .box37 {
    grid-row-start: 8;
    grid-row-end: 8;
  }

  .red-box {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .red-box-additional {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .box-additional-1 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 4;
    grid-column-end: 5;
  }

  .box-additional-2 {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 5;
    grid-column-end: 6;
  }

  .box-additional-3 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .box-additional-4 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .box-additional-5 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .box-additional-6 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 4;
    grid-column-end: 5;
  }

  .box-additional-7 {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 5;
    grid-column-end: 6;
  }

}


@media screen and (max-width: 350px) {
  .form, .form-additional {
    width: 15rem;
  }

}